.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #3f51b5);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #ff4081);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}

.mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}

.mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3f51b5;
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(63, 81, 181, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(63, 81, 181, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ff4081;
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 64, 129, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 64, 129, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #3f51b5);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #3f51b5);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #3f51b5);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(63, 81, 181, 0.87);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required, .mdc-floating-label--required[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(63, 81, 181, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(255, 64, 129, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #ff4081);
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #ff4081);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 64, 129, 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #ff4081);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #3f51b5);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #3f51b5);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-list-divider::after {
    border-bottom-color: white;
  }
}
.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(63, 81, 181, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(255, 64, 129, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #3f51b5);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #3f51b5);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-list-divider::after {
    border-bottom-color: white;
  }
}
.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #ff4081;
  --mdc-chip-elevated-disabled-container-color: #ff4081;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip {
  height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #3949ab;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #1a237e;
  --mdc-switch-selected-hover-handle-color: #1a237e;
  --mdc-switch-selected-pressed-handle-color: #1a237e;
  --mdc-switch-selected-focus-track-color: #7986cb;
  --mdc-switch-selected-hover-track-color: #7986cb;
  --mdc-switch-selected-pressed-track-color: #7986cb;
  --mdc-switch-selected-track-color: #7986cb;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mat-mdc-radio-ripple-color: #000;
}
.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-radio-button .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-mdc-radio-checked-ripple-color: #3f51b5;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
  --mat-mdc-radio-checked-ripple-color: #ff4081;
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}

.mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mat-mdc-slider-ripple-color: #3f51b5;
  --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #ff4081;
  --mdc-slider-focus-handle-color: #ff4081;
  --mdc-slider-hover-handle-color: #ff4081;
  --mdc-slider-active-track-color: #ff4081;
  --mdc-slider-inactive-track-color: #ff4081;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff4081;
  --mat-mdc-slider-ripple-color: #ff4081;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 64, 129, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 64, 129, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #3f51b5);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #3f51b5);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-list-divider::after {
    border-bottom-color: white;
  }
}
.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-list-item-interactive::before {
  background: black;
}
.mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
.mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #3f51b5;
  opacity: 0.08;
}
.mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
.mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}

.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #3f51b5);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #3f51b5);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-list-divider::after {
    border-bottom-color: white;
  }
}
.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}
.mat-mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}
.mat-mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}

.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-tab, .mat-mdc-tab-link {
  background-color: transparent;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #3f51b5;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #3f51b5;
}
.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: #3f51b5;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #ff4081;
}
.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #ff4081;
}
.mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #ff4081;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #f44336;
}
.mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header-pagination, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header-pagination {
  background-color: var(--mdc-theme-primary, #3f51b5);
}
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label {
  color: var(--mdc-theme-on-primary, #fff);
}
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-focus-indicator::before, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-focus-indicator::before, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before {
  border-color: var(--mdc-theme-on-primary, #fff);
}
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-ripple-element, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mdc-tab__ripple::before, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-ripple-element, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mdc-tab__ripple::before, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mdc-tab__ripple::before, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mdc-tab__ripple::before, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before {
  background-color: var(--mdc-theme-on-primary, #fff);
}
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-primary, #fff);
}
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header-pagination, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header-pagination {
  background-color: var(--mdc-theme-secondary, #ff4081);
}
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label {
  color: var(--mdc-theme-on-secondary, #fff);
}
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-focus-indicator::before, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-focus-indicator::before, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before {
  border-color: var(--mdc-theme-on-secondary, #fff);
}
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-ripple-element, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mdc-tab__ripple::before, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-ripple-element, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mdc-tab__ripple::before, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mdc-tab__ripple::before, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mdc-tab__ripple::before, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before {
  background-color: var(--mdc-theme-on-secondary, #fff);
}
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-secondary, #fff);
}
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header-pagination, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header-pagination {
  background-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label {
  color: var(--mdc-theme-on-error, #fff);
}
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-focus-indicator::before, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-focus-indicator::before, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before {
  border-color: var(--mdc-theme-on-error, #fff);
}
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-ripple-element, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mdc-tab__ripple::before, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-ripple-element, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mdc-tab__ripple::before, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mdc-tab__ripple::before, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mdc-tab__ripple::before, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before {
  background-color: var(--mdc-theme-on-error, #fff);
}
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron, .mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-error, #fff);
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}

.mat-mdc-tab-header .mdc-tab {
  height: 48px;
}

.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #3f51b5;
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #ff4081;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}

.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #ff4081;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ff4081;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(63, 81, 181, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #3f51b5;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #ff4081;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 81, 181, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #3f51b5;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-icon.mat-primary {
  color: #3f51b5;
}
.mat-icon.mat-accent {
  color: #ff4081;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}